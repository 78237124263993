/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.bgWhite{
    background-color: #fff !important;
}

.navbar-dark .navbar-nav .nav-link {
    color: hsla(0,0%,100%,.5) !important;
}

a.navbar-brand:hover{
    background-color: transparent !important;
}

.navbar-brand .input-field-wrapper:hover{
    cursor: pointer;
}

.modal-title{
    width: 100%;
}


.friendlist-box {
    text-decoration: none !important;
}

    .friendlist-box:hover {
        background-color: #e3e3e3;
    }

    .friendlist-box .feather {
        font-size: 32px;
        line-height: 48px;
    }

    .friendlist-box .photo-table.fa {
        font-size: 32px;
        line-height: 48px;
    }

    .friendlist-box .f-12 {
        line-height: 48px;
        font-size: 16px;
    }


.list-group-item:hover {
    border: none !important;
}

.active.list-group-item {
    border: none !important;
    z-index: 0 !important;
}

    .active.list-group-item h5 {
        color: #fff !important;
    }

    .active.list-group-item div {
        border-left: 10px solid #ffffff8a;
    }

.list-group-item {
    margin-right: 10px !important;
    background-color: #0000000a;
    border: none;
    text-align: center;
    margin-bottom: 10px !important;
}

